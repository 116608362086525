import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class IpService {
  // ip = "http://localhost:5000";
  // ip = "https://vcare-app.herokuapp.com";
  ip = "https://api.vcareservice.com";

  constructor() {}
}
