import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/service/authentication.service";
import { SidebarService } from "src/app/_helpers/sidebar.service";
import { Router } from "@angular/router";
import { LocationStrategy } from "@angular/common";
declare var $: any;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  constructor(
    private authenticationservice: AuthenticationService,
    private sidebarservice: SidebarService,
    private location: LocationStrategy
  ) {}

  ngOnInit() {
    $(document).ready(function () {
      $("#body-row .collapse").collapse("hide");
      $("#collapse-icon").addClass("fa-angle-double-left");
    });
  }
  SidebarCollapse() {
    this.sidebarservice.collapsesidebar();
    var el = document.getElementsByClassName("collapse-list");
    var el2 = document.getElementsByClassName("collapse-image-list");
    for (var i = 0; i < el.length; i++) {
      if (el[i].classList.contains("d-flex")) {
        el[i].classList.remove("d-flex");
        el[i].classList.add("text-center");
      } else {
        el[i].classList.add("d-flex");
        el[i].classList.remove("text-center");
      }
    }
    if (el2[0].classList.contains("d-none")) {
      el2[0].classList.remove("d-none");
    } else {
      el2[0].classList.add("d-none");
    }
    $(".menu-collapsed").toggleClass("d-none");
    $(".sidebar-submenu").toggleClass("d-none");
    $(".submenu-icon").toggleClass("d-none");
    $("#sidebar-container").toggleClass("sidebar-expanded sidebar-collapsed");

    $("#collapse-icon").toggleClass(
      "fa-angle-double-left fa-angle-double-right"
    );
  }
  logout() {
    this.authenticationservice.logout();
  }
}
