import { Component, OnInit } from "@angular/core";
import { BookingsService } from "src/app/service/bookings.service";
import {
  NgbDateStruct,
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { PagerService } from "src/app/_helpers/pager.service";
import { UserService } from "src/app/service/user.service";
import { ParkinglotService } from "src/app/service/parkinglot.service";
import { filterByField, filterBySearch } from "src/utils/filters";
import { filterByDate, filterByDateRange } from "src/utils/filterBydate";
import * as moment from "moment";
export interface FilterOptions {
  value: string;
  viewValue: string;
}
declare var $: any;

@Component({
  selector: "app-financial",
  templateUrl: "./financial.component.html",
  styleUrls: ["./financial.component.scss"]
})
export class FinancialComponent implements OnInit {
  options: FilterOptions[] = [
    { value: "PL", viewValue: "PARKING LOT" },
    { value: "CN", viewValue: "CLEANER NAME" },
    { value: "DT", viewValue: "DATE" }
  ];
  filterBy = null;
  hidefilterbydate = true;
  hidefilterbyLot = true;
  hidefilterbycleaner = true;
  Filterlot = "";
  FilterCleaner = "";
  hoveredDate: NgbDate;
  loader = false;
  fromDate: NgbDate;
  toDate: NgbDate;
  Today = new Date();
  searchText = "";
  private allItems: any[];
  private allItemskeep: any[];
  TotalCash: any = 0;
  PaidAmount: any = 0;
  NumberofBookings;
  pager: any = {};
  pagedItems: any[];
  todatedisable = true;
  specificBooking = {
    id: "",
    bookingID: "",
    availedServices: [],
    promoCode: "",
    Amount: "",
    offerPrize: "",
    paymentStatus: Boolean,
    createdAt: "",
    updatedAt: "",
    customerID: "",
    parkingMapID: "",
    customerDetails: {
      customerID: "",
      customerName: "",
      customerMobileNumber: "",
      countrycode: "",
      carType: "",
      plateNumber: "",
      carModel: "",
      createdAt: "",
      updatedAt: ""
    },
    netAmount: 0
  };
  constructor(
    private bookingservice: BookingsService,
    private datepipe: DatePipe,
    private pagerService: PagerService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private userservice: UserService,
    private parkinglotservice: ParkinglotService
  ) {
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), "d", 10);
  }
  BookingData;
  FilterDate: NgbDateStruct;
  FromDatevalue: NgbDateStruct;
  ToDatevalue: NgbDateStruct;

  ngOnInit() {
    this.getAlllots();
    this.getAllCleaners();
    this._refresh();
    var d = new Date();
    this.FilterDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
  }
  keepData = [];
  Alllots = [];
  _refresh(
    field: string = "",
    value: any = "",
    isDateFilter: boolean = false,
    dates: any = null
  ) {
    this.loader = false;
    this.bookingservice.getAll().subscribe(
      data => {
        data.data = data.data.map(item => ({
          ...item,
          customerDetails: {
            ...item.customerDetails,
            mobileNumber: `+${item.customerDetails.countrycode}-${item
              .customerDetails.customerMobileNumber}`,
            customerName: item.customerDetails.customerName
              ? item.customerDetails.customerName
              : "--"
          }
        }));
        this.setFilters(field, data.data, value, isDateFilter, dates);
      },
      err => {
        console.log(err);
      }
    );
  }
  setFilters(
    field: string = "",
    data: any = [],
    value: any = "",
    isDateFilter: boolean = false,
    dates: any = null
  ) {
    if (field)
      data = isDateFilter
        ? filterByDateRange(dates.from, dates.to, data, field)
        : filterByField(field, data, value);
    data = data.map(item => ({
      ...item,
      date_of_booking: moment(item.createdAt).format("MMM DD,YYYY")
    }));
    this.allItemskeep = data;
    this.allItems = [...data];
    this.keepData = [...data];
    this.findTotal(data);
    this.pager.currentPage = 1;
    this.setPage("none", this.pager.currentPage);
    this.loader = true;
  }
  FilterbyDate() {
    console.log(this.fromDate, this.toDate);

    if (this.fromDate && this.toDate) {
      this.allItems = this.allItemskeep;
      let date1 =
        this.fromDate.year +
        "-" +
        this.fromDate.month +
        "-" +
        this.fromDate.day;
      let date2 =
        this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;
      date1 = this.datepipe.transform(date1, "yyyy-MM-dd");
      date2 = this.datepipe.transform(date2, "yyyy-MM-dd");
      this.TotalCash = 0;
      this.PaidAmount = 0;
      this._refresh("createdAt", "", true, { from: date1, to: date2 });
    }
  }
  setPage(action, page: number) {
    if (action == "button") {
      window.scrollTo(0, 0);
    }
    if (action == "defualt") {
      this.allItems = this.allItemskeep;
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.BookingData = this.pagedItems;
  }
  ClearAll() {
    this.closehide = true;
    this.searchText = "";
    this.allItems = this.allItemskeep;
    this.setPage("none", this.pager.currentPage);
  }
  pickBooking(booking) {
    this.specificBooking = booking;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if (this.fromDate && this.toDate) {
      this.FilterbyDate();
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
  closehide = true;
  SearchSelect() {
    this.closehide = false;
    this.allItems = filterBySearch(
      "customerDetails.customerName",
      this.allItemskeep,
      this.searchText,
      true
    );

    this.setPage("none", this.pager.currentPage);
    if (!this.searchText) {
      this.allItems = this.allItemskeep;
      this.closehide = true;
      this.setPage("none", this.pager.currentPage);
    }
  }

  FilterOptionChange(option) {
    this.getAllCleaners();
    this.getAllCleaners();
    switch (option) {
      case "PL":
        this.hidefilterbyLot = false;
        this.hidefilterbydate = true;
        this.hidefilterbycleaner = true;
        this.FilterDate = null;
        this.fromDate = null;
        this.toDate = null;
        this.FilterCleaner = "";
        break;
      case "CN":
        this.hidefilterbycleaner = false;
        this.hidefilterbydate = true;
        this.hidefilterbyLot = true;
        this.FilterDate = null;
        this.fromDate = null;
        this.toDate = null;
        this.Filterlot = "";
        break;
      case "DT":
        this.hidefilterbydate = false;
        this.hidefilterbyLot = true;
        this.hidefilterbycleaner = true;
        this.FilterCleaner = "";
        this.Filterlot = "";
        break;
      default:
        this.hidefilterbydate = true;
        this.hidefilterbyLot = true;
        this.hidefilterbycleaner = true;
        this.FilterCleaner = "";
        this.Filterlot = "";
        this.fromDate = null;
        this.toDate = null;
    }
  }
  getAlllots() {
    this.parkinglotservice.getAlllots().subscribe(
      data => {
        this.loader = true;
        let lots = data.data;
        this.Alllots = [];
        for (let i = 0; i < lots.length; i++) {
          this.Alllots.push({
            value: lots[i].parkingLotID,
            viewValue: lots[i].parkingLotName
          });
        }
      },
      err => {
        this.loader = true;
      }
    );
  }
  AllCleaners: FilterOptions[];
  getAllCleaners() {
    this.userservice.getAllcleaners().subscribe(
      data => {
        this.loader = true;
        let cleaner = data.data;
        this.AllCleaners = [];
        for (let i = 0; i < cleaner.length; i++) {
          this.AllCleaners.push({
            value: cleaner[i].userID,
            viewValue: cleaner[i].name
          });
        }
      },
      err => {
        this.loader = true;
      }
    );
  }
  FilterByLot() {
    this._refresh("parkingLotID", this.Filterlot, false, null);
  }
  FilterByCleaner() {
    this._refresh("userID", this.FilterCleaner, false, null);
  }
  findTotal(data = []) {
    this.TotalCash = 0;
    this.PaidAmount = 0;
    this.TotalCash = data
      .reduce((partial_sum, a) => partial_sum + a.netAmount, 0)
      .toFixed(2);
    this.PaidAmount = data
      .filter(item => item.paymentStatus)
      .reduce((partial_sum, a) => partial_sum + a.netAmount, 0)
      .toFixed(2);

    this.NumberofBookings = this.allItems.length;
  }
  ShowAll() {
    this.hidefilterbydate = true;
    this.hidefilterbyLot = true;
    this.hidefilterbycleaner = true;
    this.FilterCleaner = "";
    this.Filterlot = "";
    this.FilterDate = null;
    this.filterBy = null;
    this.fromDate = null;
    this.toDate = null;
    this._refresh();
  }
  logout() {
    $("#logout").modal();
  }
  format(date: NgbDateStruct): string {
    console.log(date);

    if (!date) return "";
    return moment(`${date.month}/${date.day}/${date.year}`).format(
      "MMM/DD/YYYY"
    );
  }
  ngOnDestroy() {
    this.ShowAll();
  }
}
