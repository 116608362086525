import { Component } from "@angular/core";
import { LocationStrategy } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "vcareadmin";
  constructor(public location: LocationStrategy) {}
  ngAfterViewInit() {
    console.log(this.location.path());
    location.hash;
  }
}
