import { Component, OnInit } from "@angular/core";
import { PromotionService } from "src/app/service/promotion.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { PagerService } from "src/app/_helpers/pager.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { filterByDate } from "src/utils/filterBydate";
import * as constants from "src/constants";
import { filterBySearch } from "src/utils/filters";
declare var $: any;
@Component({
  selector: "app-promocode",
  templateUrl: "./promocode.component.html",
  styleUrls: ["./promocode.component.scss"]
})
export class PromocodeComponent implements OnInit {
  dataCount = 0;
  sortByValue = null;
  sortType = null;
  erroralert = true;
  successalert = true;
  errorMsg = "";
  submitted = false;
  PromotionList;
  loader = false;
  errorMessage = "";
  hasError = false;
  searchText;
  promoeditbody = {
    promoID: "",
    promoName: "",
    promoDescription: "",
    promoCode: "",
    offerPrice: 0,
    validUpto: "",
    createdAt: "",
    updatedAt: "",
    eligibleprice: 0
  };
  promobody = {
    promoName: "",
    promoDescription: "",
    promoCode: "",
    offerPrice: "",
    validUpto: "",
    eligibleprice: ""
  };
  validupto: NgbDateStruct;
  validuptoedit: NgbDateStruct;
  ChooseDate: NgbDateStruct;
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  keepData: any[];
  PromotionForm: FormGroup;
  PromotioneditForm: FormGroup;
  minDate;
  constructor(
    private promoservice: PromotionService,
    private datepipe: DatePipe,
    private pagerService: PagerService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    let date = new Date();
    this.minDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
    this.PromotionForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      description: [null, [Validators.required]],
      code: [null, [Validators.required]],
      price: [null, [Validators.required, Validators.min(1)]],
      valid: [null, [Validators.required]],
      minimum: [null, [Validators.required]]
    });
    this.PromotioneditForm = this.formBuilder.group({
      ename: [null, [Validators.required]],
      edescription: [null, [Validators.required]],
      ecode: [null, [Validators.required]],
      eprice: [null, [Validators.required, Validators.min(1)]],
      evalid: [null, [Validators.required]],
      eminimum: [null, [Validators.required]]
    });
    this._refresh();
    this.sortByValue = "PC";
    this.sortType = "A";
  }
  get f() {
    return this.PromotionForm.controls;
  }
  get g() {
    return this.PromotioneditForm.controls;
  }
  async _refresh(date: string = "") {
    this.loader = false;

    this.promoservice.getAll().subscribe(
      data => {
        let array = date
          ? filterByDate(date, data.data, "validUpto")
          : data.data;
        this.dataCount = array.length;
        this.PromotionList = [...array];
        this.keepData = [...array];
        this.allItems = [...array];
        this.setPage(1);
        this.sortBy();
        if (!date) this.ChooseDate = null;
        this.loader = true;
      },
      err => console.log(err)
    );
  }
  previoscode = "";
  pickPromo(promo) {
    this.submitted = false;
    this.promoeditbody = {
      promoID: "",
      promoName: "",
      promoDescription: "",
      promoCode: "",
      offerPrice: 0,
      validUpto: "",
      createdAt: "",
      updatedAt: "",
      eligibleprice: 0
    };
    promo.offerPrice = parseFloat(promo.offerPrice);
    promo.eligibleprice = parseFloat(promo.eligibleprice);
    this.promoeditbody = promo;
    this.previoscode = this.promoeditbody.promoCode;
    let year = this.datepipe.transform(this.promoeditbody.validUpto, "yyyy");
    let month = this.datepipe.transform(this.promoeditbody.validUpto, "MM");
    let day = this.datepipe.transform(this.promoeditbody.validUpto, "dd");
    this.validuptoedit = {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day)
    };
  }
  AddPromo(body) {
    this.submitted = true;

    if (this.PromotionForm.invalid) {
      return;
    }
    let fields = [
      {
        field: "promoName",
        label: "Promotion name",
        limit: constants.default.CHARACTER_LIMIT_FOR_NAME,
        isString: true,
        regex: constants.default.REGEX.ALPHA_NUMERIC_NAME
      },
      {
        field: "promoDescription",
        label: "Promotion description",
        limit: constants.default.CHARACTER_LIMIT_FOR_DESCRIPTION,
        isString: true,
        regex: constants.default.REGEX.ALPHA_NUMERIC_DESCRIPTION
      },
      {
        field: "promoCode",
        label: "Promotion code",
        limit: constants.default.CHARACTER_LIMIT_FOR_PROMO_CODE,
        isString: true,
        regex: constants.default.REGEX.ALPHA_NUMERIC_PROMO
      },
      {
        field: "offerPrice",
        label: "Offer price",
        limit: constants.default.CHARACTER_LIMIT_FOR_AMOUNT,
        isString: false
      },
      {
        field: "eligibleprice",
        label: "Eligible price",
        limit: constants.default.CHARACTER_LIMIT_FOR_AMOUNT,
        isString: false
      }
    ];
    for (const item of fields) {
      let _valid = this.checkLimits(
        body,
        item.field,
        item.limit,
        item.label,
        item.isString,
        item.regex
      );
      if (!_valid) return;
    }
    if (parseFloat(body.eligibleprice) < parseFloat(body.offerPrice)) {
      this.errorMsg = "Minimum price must be greater than offer price";
      this.erroralert = false;
      return;
    }
    for (var i = 0; i < this.PromotionList.length; i++) {
      if (
        this.PromotionList[i].promoCode.trim().toUpperCase() ==
        body.promoCode.trim().toUpperCase()
      ) {
        this.errorMsg = "Promocode already exist";
        this.erroralert = false;
        return;
      }
    }

    this.errorMsg = "";
    this.erroralert = true;

    body.validUpto =
      this.validupto.year +
      "-" +
      this.validupto.month +
      "-" +
      this.validupto.day;
    this.promoservice.Addnew(body).subscribe(
      data => {
        this.PromotionForm.reset();
        this.submitted = false;
        this.errorMsg = "";
        this.erroralert = true;
        this.successalert = false;
        this._refresh();
        setTimeout(() => {
          this.successalert = true;
          $("#addnew").modal("hide");
        }, 1000);
        this.promobody = {
          promoName: "",
          promoDescription: "",
          promoCode: "",
          offerPrice: "",
          validUpto: "",
          eligibleprice: ""
        };
      },
      err => {
        this.PromotionForm.reset();
        this.submitted = false;
        this.erroralert = false;
        this.errorMsg = err.error.error;
      }
    );
  }
  Delete() {
    this.promoservice.delete(this.promoeditbody).subscribe(
      data => {
        this._refresh();
      },
      err => {
        console.log(err);
      }
    );
  }
  async EditPromo(body) {
    this.submitted = true;
    this.errorMsg = "";
    this.erroralert = true;
    if (this.PromotioneditForm.invalid) {
      return;
    }
    let fields = [
      {
        field: "promoName",
        label: "Promotion name",
        limit: constants.default.CHARACTER_LIMIT_FOR_NAME,
        isString: true,
        regex: constants.default.REGEX.ALPHA_NUMERIC_NAME
      },
      {
        field: "promoDescription",
        label: "Promotion description",
        limit: constants.default.CHARACTER_LIMIT_FOR_DESCRIPTION,
        isString: true,
        regex: constants.default.REGEX.ALPHA_NUMERIC_DESCRIPTION
      },
      {
        field: "promoCode",
        label: "Promotion code",
        limit: constants.default.CHARACTER_LIMIT_FOR_PROMO_CODE,
        isString: true,
        regex: constants.default.REGEX.ALPHA_NUMERIC_PROMO
      },
      {
        field: "offerPrice",
        label: "Offer price",
        limit: constants.default.CHARACTER_LIMIT_FOR_AMOUNT,
        isString: false
      },
      {
        field: "eligibleprice",
        label: "Eligible price",
        limit: constants.default.CHARACTER_LIMIT_FOR_AMOUNT,
        isString: false
      }
    ];
    for (const item of fields) {
      let _valid = this.checkLimits(
        body,
        item.field,
        item.limit,
        item.label,
        item.isString,
        item.regex
      );
      if (!_valid) return;
    }

    if (parseFloat(body.eligibleprice) < parseFloat(body.offerPrice)) {
      this.errorMsg = "Minimum price must be greater than offer price";
      this.erroralert = false;
      return;
    }

    this.promoservice.getAll().subscribe(
      data => {
        let promolist = data.data;
        for (var i = 0; i < promolist.length; i++) {
          if (
            promolist[i].promoCode.trim().toUpperCase() ==
              body.promoCode.trim().toUpperCase() &&
            body.promoCode.trim().toUpperCase() !=
              this.previoscode.trim().toUpperCase()
          ) {
            this.errorMsg = "Promocode already exist";
            this.erroralert = false;
            return;
          }
        }
      },
      err => console.log(err)
    );

    this.errorMsg = "";
    this.erroralert = true;

    body.validUpto =
      this.validuptoedit.year +
      "-" +
      this.validuptoedit.month +
      "-" +
      this.validuptoedit.day;
    this.promoservice.Edit(body).subscribe(
      data => {
        this.PromotioneditForm.reset();
        this.submitted = false;
        this.errorMsg = "";
        this.erroralert = true;
        this.successalert = false;
        this._refresh();
        setTimeout(() => {
          this.successalert = true;
          $("#edit").modal("hide");
        }, 1000);
      },
      err => {
        this.PromotioneditForm.reset();
        this.submitted = false;
        this._refresh();
        this.erroralert = false;
        console.log(err.error);
        this.errorMsg = err.error.error;
      }
    );
  }

  ShowAll() {
    this.ChooseDate = null;
    this.sortType = "A";
    this.sortByValue = "PC";
    this._refresh();
  }
  FilterbyDate() {
    if (this.ChooseDate) {
      let choosedate =
        this.ChooseDate.year +
        "-" +
        this.ChooseDate.month +
        "-" +
        this.ChooseDate.day;
      this._refresh(choosedate);
    }
  }
  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.PromotionList = this.pagedItems;
  }
  sortBy() {
    console.log(this.sortByValue, this.sortType);

    this.PromotionList = this.allItems;
    if (this.sortByValue == "PC") {
      if (this.sortType == "A") {
        let sortArray = [];

        sortArray = this.PromotionList;
        sortArray.sort(function(x, y) {
          if (x.promoCode.toUpperCase() < y.promoCode.toUpperCase()) return -1;
          if (x.promoCode.toUpperCase() > y.promoCode.toUpperCase()) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage(this.pager.currentPage);
      } else if (this.sortType == "D") {
        let sortArray = [];

        sortArray = this.PromotionList;
        sortArray.sort((x, y) => {
          if (x.promoCode.toUpperCase() > y.promoCode.toUpperCase()) return -1;
          if (x.promoCode.toUpperCase() < y.promoCode.toUpperCase()) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage(this.pager.currentPage);
      }
    } else if (this.sortByValue == "PR") {
      if (this.sortType == "A") {
        let sortArray = [];

        sortArray = this.PromotionList;
        sortArray.sort(function(x, y) {
          if (parseFloat(x.offerPrice) < parseFloat(y.offerPrice)) return -1;
          if (parseFloat(x.offerPrice) > parseFloat(y.offerPrice)) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage(this.pager.currentPage);
      } else if (this.sortType == "D") {
        let sortArray = [];

        sortArray = this.PromotionList;
        sortArray.sort((x, y) => {
          if (parseFloat(x.offerPrice) > parseFloat(y.offerPrice)) return -1;
          if (parseFloat(x.offerPrice) < parseFloat(y.offerPrice)) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage(this.pager.currentPage);
      }
    } else if (this.sortByValue == "VD") {
      if (this.sortType == "A") {
        let sortArray = [];

        sortArray = this.PromotionList;
        sortArray.sort(function(x, y) {
          if (x.validUpto < y.validUpto) return -1;
          if (x.validUpto > y.validUpto) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage(this.pager.currentPage);
      } else if (this.sortType == "D") {
        let sortArray = [];

        sortArray = this.PromotionList;
        sortArray.sort((x, y) => {
          if (x.validUpto > y.validUpto) return -1;
          if (x.validUpto < y.validUpto) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage(this.pager.currentPage);
      }
    }
  }

  modalclose() {
    this._refresh();
    this.errorMsg = "";
    this.erroralert = true;
    this.errorMessage = "";
    this.hasError = false;
  }
  closehide = true;
  SearchSelect() {
    this.closehide = false;
    this.allItems = filterBySearch("promoCode", this.keepData, this.searchText);

    this.sortBy();
    if (!this.searchText) {
      this.allItems = this.keepData;
      this.closehide = true;
      this.sortBy();
    }
  }
  clearsearch() {
    this.searchText = "";
    this.closehide = true;
    this.allItems = this.keepData;
    this.setPage(this.pager.currentPage);
  }
  ResetForm() {
    this.PromotionForm.reset();
    this.submitted = false;
    this.clearErrors();
  }
  clearErrors() {
    this.successalert = true;
    this.errorMessage = "";
    this.hasError = false;
  }
  omit_special_char(event: any, field: string) {
    let alphaNumericRegex =
      field !== "code"
        ? /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 -]*)?$/
        : /^[a-zA-Z0-9-]$/;
    let limit = 0;
    switch (field) {
      case "code":
        limit = constants.default.CHARACTER_LIMIT_FOR_PROMO_CODE;
        break;
      case "name":
        limit = constants.default.CHARACTER_LIMIT_FOR_NAME;
        break;
      case "desc":
        limit = constants.default.CHARACTER_LIMIT_FOR_DESCRIPTION;
        break;

      default:
        break;
    }
    if (field === "code" && event.code === "Space") return false;
    if (
      (!(event.target.value && event.code === "Space") &&
        !alphaNumericRegex.test(event.key)) ||
      event.target.value.length >= limit
    ) {
      return false;
    }
    return true;
  }
  omit_alphabets_char(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      ((charCode > 31 && (charCode < 48 || charCode > 57)) ||
        event.target.value >= constants.default.CHARACTER_LIMIT_FOR_AMOUNT) &&
      charCode !== 46
    ) {
      return false;
    }
    return true;
  }
  logout() {
    $("#logout").modal();
  }
  checkLimits(obj, field, limit, label, isString = false, regex) {
    if (isString && !regex.test(obj[field])) {
      this.hasError = true;
      this.errorMessage = `Invalid ${label}`;
      return false;
    }
    if (
      (isString && obj[field].length > limit) ||
      (!isString && obj[field] > limit)
    ) {
      this.hasError = true;
      this.errorMessage = `${label} ${isString
        ? "length"
        : ""} should be below ${isString ? "" : "AED"} ${limit}`;
      return false;
    }
    this.hasError = false;
    this.errorMessage = "";
    return true;
  }
}
