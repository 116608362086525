export default {
  CHARACTER_LIMIT_FOR_NAME: 50,
  CHARACTER_LIMIT_FOR_LOCATION: 100,
  CHARACTER_LIMIT_FOR_DESCRIPTION: 100,
  CHARACTER_LIMIT_FOR_AMOUNT: 9999,
  CHARACTER_LIMIT_FOR_PROMO_CODE: 20,
  CHARACTER_LIMIT_FOR_FLOAT: 2,
  REGEX: {
    ALPHA_NUMERIC_NAME: /^[a-zA-Z]{1}([a-zA-Z0-9 ]){0,50}$/,
    ALPHA_NUMERIC_LOCATION: /^[a-zA-Z]{1}([a-zA-Z0-9 ]){0,100}$/,
    ALPHA_NUMERIC_DESCRIPTION: /^[a-zA-Z]{1}([a-zA-Z0-9 ]){0,100}$/,
    ALPHA_NUMERIC_PROMO: /^[a-zA-Z]{1}([a-zA-Z0-9 -]){0,100}$/,
    ALPHABETS_ONLY: /^[a-zA-Z]{1}([a-zA-Z ]){0,50}$/,
  },
};
