import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/service/authentication.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginData = {
    userName: "",
    password: "",
    role: ""
  };
  loader = true;
  erroralert = true;
  errormsg = "";
  loginForm: FormGroup;
  submitted = false;
  loading = false;
  returnUrl: string;
  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(["/parkinglots"]);
      this.returnUrl =
        this.route.snapshot.queryParams["returnUrl"] || "/parkinglots";
    }
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required]],
      pwd: ["", [Validators.required]]
    });
    // this.openSnackBar();
  }
  get f() {
    return this.loginForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      let message = "Username and password are required";
      let action = "cancel";
      this.openSnackBar(message, action);
      return;
    }
    this.loader = false;
    this.errormsg = "";
    this.erroralert = true;
    this.authenticationService.login(this.loginData).subscribe(
      data => {
        this.erroralert = true;
        this.loader = true;
        this.router.navigate([this.returnUrl || "/parkinglots"]);
      },
      error => {
        console.log(error);
        this.errormsg = error.error.error;
        this.loader = true;
        this.erroralert = false;
      }
    );
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 10000
    });
  }
}
