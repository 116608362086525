import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./screens/login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ParkingLotsComponent } from "./screens/parking-lots/parking-lots.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  JwtInterceptor,
  ErrorInterceptor,
  fakeBackendProvider,
  AuthGuardGuard,
} from "./_helpers";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { MatSelectModule } from "@angular/material/select";
import { NgbModule, NgbDate, NgbDatepicker } from "@ng-bootstrap/ng-bootstrap";
import { ServicesComponent } from "./screens/services/services.component";
import { CleanersComponent } from "./screens/cleaners/cleaners.component";
import { PromocodeComponent } from "./screens/promocode/promocode.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import {
  DatePipe,
  LocationStrategy,
  HashLocationStrategy,
  JsonPipe,
} from "@angular/common";
import { PagerService } from "./_helpers/pager.service";
import { DailystatisticsComponent } from "./screens/dailystatistics/dailystatistics.component";
import { FinancialComponent } from "./screens/financial/financial.component";
import { BookinghistoryComponent } from "./screens/bookinghistory/bookinghistory.component";
import { GenerateinvoiceComponent } from "./screens/generateinvoice/generateinvoice.component";
import { InvoiceprintComponent } from "./screens/invoiceprint/invoiceprint.component";
import { NgbdToastGlobalModule } from "./_models/globalToast/toast-global.module";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AuthenticationService } from "./service/authentication.service";
import { PromotionService } from "./service/promotion.service";
import { ParkinglotService } from "./service/parkinglot.service";
import { ServicesService } from "./service/services.service";
import { UserService } from "./service/user.service";
import { BookingsService } from "./service/bookings.service";
import { LogoutModalComponent } from "./components/logout-modal/logout-modal.component";
import { AppTwoDigitDecimaNumberDirective } from "./directives/app-two-digit-decima-number.directive";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ParkingLotsComponent,
    SidebarComponent,
    ServicesComponent,
    CleanersComponent,
    PromocodeComponent,
    DailystatisticsComponent,
    FinancialComponent,
    BookinghistoryComponent,
    GenerateinvoiceComponent,
    InvoiceprintComponent,
    LogoutModalComponent,
    AppTwoDigitDecimaNumberDirective,
    // FilterPipe
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    HttpClientModule,
    MatSelectModule,
    NgbModule,
    Ng2SearchPipeModule,
    NgbdToastGlobalModule,
    MatTooltipModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // provider used to create fake backend
    fakeBackendProvider,
    DatePipe,
    JsonPipe,
    PagerService,
    AuthenticationService,
    PromotionService,
    ParkinglotService,
    ServicesService,
    UserService,
    BookingsService,
    AuthGuardGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
