import { Component, OnInit } from "@angular/core";
import { ServicesService } from "src/app/service/services.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { PagerService } from "src/app/_helpers/pager.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { filterByDate } from "src/utils/filterBydate";
import * as constants from "src/constants";
import { filterBySearch } from "src/utils/filters";

declare var $: any;
@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"]
})
export class ServicesComponent implements OnInit {
  loader = false;
  ChooseDate: NgbDateStruct;
  ServiceForm: FormGroup;
  ServiceeditForm: FormGroup;
  submitted = false;
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  keepData: any[];
  errorMessage = "";
  hasError = false;

  constructor(
    private Servicemodel: ServicesService,
    private datepipe: DatePipe,
    private pagerService: PagerService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.ServiceForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      pickup: [null, [Validators.required, Validators.min(1)]],
      sedan: [null, [Validators.required, Validators.min(1)]],
      FxF: [null, [Validators.required, Validators.min(1)]],
      suv: [null, [Validators.required, Validators.min(1)]]
    });
    this.ServiceeditForm = this.formBuilder.group({
      ename: [null, [Validators.required]],
      epickup: [null, [Validators.required, Validators.min(1)]],
      esedan: [null, [Validators.required, Validators.min(1)]],
      eFxF: [null, [Validators.required, Validators.min(1)]],
      esuv: [null, [Validators.required, Validators.min(1)]]
    });
    this._refresh();
    this.SortByValue = "SN";
    this.SortType = "A";
  }
  ngAfterViewInit() {
    var el = document.getElementById("showAll");
    el.classList.add("active");
    let sort = document.getElementsByClassName("sort-by");
    for (let i = 0; i < sort.length; i++) {
      sort[i].classList.add("active");
    }
  }
  get f() {
    return this.ServiceForm.controls;
  }
  get g() {
    return this.ServiceeditForm.controls;
  }
  dataCount = 0;
  SortByValue = null;
  SortType = null;
  erroralert = true;
  successalert = true;
  errorMsg = "";
  searchText;
  Servicebody = {
    serviceName: "",
    CostOfHatchback: "",
    CostOfSedan: "",
    CostOfMUV: "",
    CostOfSevenseater: ""
  };
  ServiceEditbody = {
    ServiceID: "",
    serviceName: "",
    CostOfHatchback: "",
    CostOfSedan: "",
    CostOfMUV: "",
    CostOfSevenseater: "",
    createdAt: "",
    updatedAt: ""
  };
  ServiceData;
  _refresh(date: string = "") {
    this.loader = false;
    this.Servicemodel.getAll().subscribe(
      data => {
        this.loader = true;
        let array = date ? filterByDate(date, data.data) : data.data;
        this.dataCount = array.length;
        this.ServiceData = [...array];
        this.keepData = [...array];
        this.allItems = [...array];
        this.setPage("none", 1);
        this.sortBy();
        if (!date) this.ChooseDate = null;
      },
      err => {
        console.log(err);
        this.loader = true;
      }
    );
  }
  CancelAll() {
    this.searchText = "";
  }
  AddService(body) {
    this.submitted = true;
    console.log(this.ServiceForm.invalid);

    if (this.ServiceForm.invalid) {
      return;
    }

    let fields = [
      {
        carType: "Service Name",
        costKey: "serviceName",
        regex: constants.default.REGEX.ALPHA_NUMERIC_NAME
      },
      { carType: "Cost of Pickup", costKey: "CostOfHatchback" },
      { carType: "Cost of Sedan", costKey: "CostOfSedan" },
      { carType: "Cost of SUV", costKey: "CostOfMUV" },
      { carType: "Cost of 4x4", costKey: "CostOfSevenseater" }
    ];
    for (const item of fields) {
      let valid = this.checkLimits(
        body,
        item.costKey,
        item.costKey === "serviceName"
          ? constants.default.CHARACTER_LIMIT_FOR_NAME
          : constants.default.CHARACTER_LIMIT_FOR_AMOUNT,
        item.carType,
        item.costKey === "serviceName",
        item.regex
      );

      if (!valid) return;
    }
    for (const item of fields) {
      if (item.costKey === "serviceName") continue;
      body[item.costKey] = Math.round(body[item.costKey] * 100) / 100;
    }

    for (var i = 0; i < this.ServiceData.length; i++) {
      if (
        this.ServiceData[i].serviceName.toUpperCase().replace(/ /g, "") ==
        body.serviceName.toUpperCase().replace(/ /g, "")
      ) {
        this.errorMsg = "Service already exist";
        this.erroralert = false;
        return;
      }
    }

    this.loader = false;
    this.Servicemodel.Addnew(body).subscribe(
      data => {
        this.errorMsg = "";
        this.erroralert = true;
        this.successalert = false;
        this.submitted = false;
        this.ServiceForm.reset();
        this._refresh();
        setTimeout(() => {
          this.successalert = true;
          $("#addnew").modal("hide");
        }, 1000);
      },
      err => {
        this.loader = true;
        this.submitted = false;
        this.ServiceForm.reset();
        this.erroralert = false;
        console.log(err.error);
        this.errorMsg = err.error.error;
      }
    );
  }
  previosName = "";
  pickService(service) {
    this.previosName = service.serviceName;
    this.submitted = false;
    this.ServiceEditbody = service;
  }
  Delete() {
    this.loader = false;
    this.Servicemodel.delete(this.ServiceEditbody).subscribe(
      data => {
        this._refresh();
      },
      err => {
        this.loader = true;
        console.log(err);
      }
    );
  }
  EditService(body) {
    this.submitted = true;
    if (this.ServiceeditForm.invalid) {
      return;
    }

    let fields = [
      {
        carType: "Service Name",
        costKey: "serviceName",
        regex: constants.default.REGEX.ALPHA_NUMERIC_NAME
      },
      { carType: "Cost of Pickup", costKey: "CostOfHatchback" },
      { carType: "Cost of Sedan", costKey: "CostOfSedan" },
      { carType: "Cost of SUV", costKey: "CostOfMUV" },
      { carType: "Cost of 4x4", costKey: "CostOfSevenseater" }
    ];
    for (const item of fields) {
      let valid = this.checkLimits(
        body,
        item.costKey,
        item.costKey === "serviceName"
          ? constants.default.CHARACTER_LIMIT_FOR_NAME
          : constants.default.CHARACTER_LIMIT_FOR_AMOUNT,
        item.carType,
        item.costKey === "serviceName",
        item.regex
      );

      if (!valid) return;
    }

    for (const item of fields) {
      if (item.costKey === "serviceName") continue;

      body[item.costKey] = Math.round(body[item.costKey] * 100) / 100;
    }

    this.Servicemodel.getAll().subscribe(
      data => {
        let serviceData = data.data;
        for (var i = 0; i < serviceData.length; i++) {
          if (
            serviceData[i].serviceName.toUpperCase() ==
              body.serviceName.toUpperCase() &&
            body.serviceName.toUpperCase() != this.previosName.toUpperCase()
          ) {
            this.errorMsg = "Service already exist";
            this.erroralert = false;
            return;
          }
        }
        this.loader = false;
        this.Servicemodel.Edit(body).subscribe(
          data => {
            this.errorMsg = "";
            this.erroralert = true;
            this.successalert = false;
            this.submitted = false;
            this.ServiceeditForm.reset();
            this._refresh();

            setTimeout(() => {
              this.successalert = true;
              $("#edit").modal("hide");
            }, 1000);
          },
          err => {
            this.submitted = false;
            this.ServiceForm.reset();
            this.erroralert = false;
            this._refresh();
            console.log(err.error);
            this.errorMsg = err.error.error;
          }
        );
      },
      err => {
        console.log(err);
        this.loader = true;
      }
    );
  }
  ShowAll() {
    let showall = document.getElementById("showAll");
    showall.classList.add("active");

    let el = document.getElementsByClassName("filter-by-date");
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove("active");
    }
    this.ChooseDate = null;
    this.SortByValue = "SN";
    this.SortType = "A";
    this._refresh();
  }
  FilterbyDate() {
    if (this.ChooseDate) {
      this.keepData = this.allItems;
      let showall = document.getElementById("showAll");
      showall.classList.remove("active");
      let el = document.getElementsByClassName("filter-by-date");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.add("active");
      }
      let choosedate =
        this.ChooseDate.year +
        "-" +
        this.ChooseDate.month +
        "-" +
        this.ChooseDate.day;
      this._refresh(choosedate);
    }
  }
  setPage(action, page: number) {
    if (action == "button") {
      window.scrollTo(0, 0);
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.ServiceData = this.pagedItems;
  }
  sortBy() {
    let showall = document.getElementById("showAll");
    showall.classList.remove("active");
    let sort = document.getElementsByClassName("sort-by");
    for (let i = 0; i < sort.length; i++) {
      sort[i].classList.add("active");
    }
    if (!this.ChooseDate) {
      let el = document.getElementsByClassName("filter-by-date");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove("active");
      }
    }
    this.ServiceData = this.allItems;
    if (this.SortByValue == "SN") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.ServiceData;
        sortArray.sort(function(x, y) {
          if (x.serviceName.toUpperCase() < y.serviceName.toUpperCase())
            return -1;
          if (x.serviceName.toUpperCase() > y.serviceName.toUpperCase())
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.ServiceData;
        sortArray.sort((x, y) => {
          if (x.serviceName.toUpperCase() > y.serviceName.toUpperCase())
            return -1;
          if (x.serviceName.toUpperCase() < y.serviceName.toUpperCase())
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    } else if (this.SortByValue == "DT") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.ServiceData;
        sortArray.sort(function(x, y) {
          if (x.updatedAt < y.updatedAt) return -1;
          if (x.updatedAt > y.updatedAt) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.ServiceData;
        sortArray.sort((x, y) => {
          if (x.updatedAt > y.updatedAt) return -1;
          if (x.updatedAt < y.updatedAt) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    }
  }

  closehide = true;

  SearchSelect() {
    this.closehide = false;
    this.allItems = filterBySearch(
      "serviceName",
      this.keepData,
      this.searchText
    );
    this.sortBy();
    if (!this.searchText) {
      this.allItems = this.keepData;
      this.closehide = true;
      this.sortBy();
    }
  }
  clearsearch() {
    this.searchText = "";
    this.allItems = this.keepData;
    this.closehide = true;
    this.sortBy();
  }
  resetForm() {
    this.ServiceForm.reset();
    this.erroralert = true;
    this.successalert = true;
    this.submitted = false;
    this.clearErrors();
  }
  clearErrors() {
    this.successalert = true;
    this.errorMessage = "";
    this.hasError = false;
  }
  modalclose() {
    this._refresh();
    this.errorMsg = "";
    this.erroralert = true;
    this.errorMessage = "";
    this.hasError = false;
  }
  omit_special_char(event: any) {
    // let alphaNumericRegex = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 ]*)?$/;
    let alphaNumericRegex = /^[a-zA-Z0-9-]/;

    if (
      (!(event.target.value && event.code === "Space") &&
        !alphaNumericRegex.test(event.key)) ||
      event.target.value.length >= constants.default.CHARACTER_LIMIT_FOR_NAME
    ) {
      return false;
    }
    return true;
  }
  omit_alphabets_char(event) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      return false;
    }
    return true;
  }
  logout() {
    $("#logout").modal();
  }
  checkLimits(obj, field, limit, label, isString = false, regex) {
    if (isString && !regex.test(obj[field])) {
      this.hasError = true;
      this.errorMessage = `Invalid ${label}`;
      return false;
    }
    if (
      (isString && obj[field].length > limit) ||
      (!isString && obj[field] > limit)
    ) {
      this.hasError = true;
      this.errorMessage = `${label} ${isString
        ? "length"
        : ""} should be below ${isString ? "" : "AED"} ${limit}`;
      return false;
    }
    this.hasError = false;
    this.errorMessage = "";
    return true;
  }
}
