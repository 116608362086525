import { Directive, ElementRef, HostListener } from "@angular/core";
@Directive({
  selector: "[appAppTwoDigitDecimaNumber]",
})
export class AppTwoDigitDecimaNumberDirective {
  private regex: RegExp = /^(?:\d*\.\d{1,1}|\d+)$/;
  private specialKeys: Array<string> = [
    "Backspace",
    "Tab",
    "End",
    "Home",
    "-",
    "ArrowLeft",
    "ArrowRight",
    "Del",
    "Delete",
  ];
  constructor(private el: ElementRef) {}
  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    console.log(this.el.nativeElement.value);
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (!this.el.nativeElement.value) return true;
    return this.regex.test(this.el.nativeElement.value);
    // let current: string = this.el.nativeElement.value;
    // const position = this.el.nativeElement.selectionStart;
    // console.log(position);

    // const next: string = [
    //   current.slice(0, position),
    //   event.key == "Decimal" ? "." : event.key,
    //   current.slice(position),
    // ].join("");
    // if (next && !String(next).match(this.regex)) {
    //   event.preventDefault();
    // }
  }
}
