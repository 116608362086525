import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/service/authentication.service";
declare var $: any;
@Component({
  selector: "app-logout-modal",
  templateUrl: "./logout-modal.component.html",
  styleUrls: ["./logout-modal.component.scss"],
})
export class LogoutModalComponent implements OnInit {
  constructor(private authenticationservice: AuthenticationService) {}

  ngOnInit() {}
  logout() {
    this.authenticationservice.logout();
  }
  ngAfterViewInit() {}
}
