import { Component, OnInit } from "@angular/core";
import { NgbCalendar, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { ParkinglotService } from "src/app/service/parkinglot.service";
import { AuthenticationService } from "src/app/service/authentication.service";
import { UserService } from "src/app/service/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DatePipe } from "@angular/common";
import { PagerService } from "src/app/_helpers/pager.service";
import { FormControl } from "@angular/forms";
import * as constants from "src/constants";
import { filterByDate } from "src/utils/filterBydate";
import { filterBySearch } from "src/utils/filters";
declare var $: any;
@Component({
  selector: "app-parking-lots",
  templateUrl: "./parking-lots.component.html",
  styleUrls: ["./parking-lots.component.scss"]
})
export class ParkingLotsComponent implements OnInit {
  ChooseDate: NgbDateStruct;
  loader = false;
  keepData;
  SortByValue = null;
  SortType = null;
  userList;
  errors = {};
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  submitted = false;
  successalert = true;
  parkinglotArray;
  editmodal = false;
  selectValue = "";
  presearchText = "";
  serachItems = [];
  searchText;
  date: { year: number; month: number };
  errorMessage = "";
  hasError = false;
  MappedCleaners = [];
  dummy: { id: 1 };
  specifiedLot = {
    parkingLotID: "",
    parkingLotName: "",
    parkingLotLocation: "",
    createdAt: "",
    updatedAt: "",
    parkinglotdetails: []
  };
  // specifiedLot;
  editLotUserID = [];
  ParkingLotData = {
    parkingLotName: "",
    parkingLotLocation: "",
    userID: []
  };
  ParkingLotMapping = {
    parkingLotID: "",
    userID: []
  };
  usereditList = [];

  constructor(
    private parkinglotService: ParkinglotService,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private datepipe: DatePipe,
    private pagerService: PagerService
  ) {}
  dataCount = 0;
  ngOnInit() {
    this._refresh();
    this.getCleaners();
    this.SortByValue = "DT";
    this.SortType = "D";
  }
  ngAfterViewInit() {
    var el = document.getElementById("showAll");
    el.classList.add("active");
    let sort = document.getElementsByClassName("sort-by");
    for (let i = 0; i < sort.length; i++) {
      sort[i].classList.add("active");
    }
  }
  _refresh(date: string = "") {
    this.loader = false;
    this.getCleaners();
    this.parkinglotService.getAlllots().subscribe(
      data => {
        let array = date ? filterByDate(date, data.data) : data.data;

        this.dataCount = array.length;

        this.parkinglotArray = array;
        this.keepData = [...array];
        this.allItems = [...array];
        this.setPage("none", 1);
        this.sortBy();
        if (!date) this.ChooseDate = null;
      },
      error => {
        console.log(error);
        this.loader = true;
      }
    );
  }
  PickLot(action, lot) {
    this.usereditList = [];
    this.editLotUserID = [];

    for (let i = 0; i < lot.parkinglotdetails.length; i++) {
      this.editLotUserID.push(lot.parkinglotdetails[i].userID);
    }

    let AllCleaner = [];
    let previousUser = [];
    if (action == "edit") {
      this.loader = false;
      this.userService.getAllcleaners().subscribe(
        data => {
          AllCleaner = data.data;
          for (let i = 0; i < AllCleaner.length; i++) {
            for (let j = 0; j < this.editLotUserID.length; j++) {
              if (AllCleaner[i].userID == this.editLotUserID[j]) {
                previousUser.push(AllCleaner[i]);
              }
            }
          }
          this.usereditList.push(...previousUser, ...this.userList);

          setTimeout(() => {
            this.usereditList = this.usereditList.map(item => ({
              ...item,
              checked: this.editLotUserID.some(id => id === item.userID)
            }));

            this.loader = true;
            this.MappedCleaners = this.editLotUserID;
          }, 1000);
        },
        err => console.log(err)
      );
    }
    this.editmodal = true;
    this.successalert = true;
    this.specifiedLot = lot;
  }
  userlistcheck;
  AllCleaner;
  async getCleaners() {
    this.userService.getAllcleaners().subscribe(
      data => {
        this.userlistcheck = data.data;
        this.getAllMapping();
      },
      err => console.log(err)
    );
  }

  AddNewLot(body: any) {
    this.submitted = true;
    this.errors = {};
    this.MappedCleaners = [];
    Object.keys(body).forEach(key => {
      if (!body[key]) {
        this.errors[key] = `required`;
      }
    });
    if (Object.keys(this.errors).length || !body.userID.length) {
      return;
    }

    let fields = [
      {
        field: "parkingLotName",
        label: "Parkinglot name",
        regex: constants.default.REGEX.ALPHA_NUMERIC_NAME
      },
      {
        field: "parkingLotLocation",
        label: "Parkinglot location",
        regex: constants.default.REGEX.ALPHA_NUMERIC_LOCATION
      }
    ];
    for (const item of fields) {
      let valid = this.checkLimits(
        body,
        item.field,
        item.field === "parkingLotName"
          ? constants.default.CHARACTER_LIMIT_FOR_NAME
          : constants.default.CHARACTER_LIMIT_FOR_LOCATION,
        item.label,
        true,
        item.regex
      );
      if (!valid) return;
    }
    this.loader = false;
    this.parkinglotService.CreateNew(body).subscribe(
      data => {
        if (data.status == 200) {
          this.submitted = false;
          this.errors = {};
          if (this.ParkingLotData.userID.length != 0) {
            this.ParkingLotMapping.parkingLotID = data.data.parkingLotID;
            this.ParkingLotMapping.userID = this.ParkingLotData.userID;
            this.lotMapping();
          } else {
            this.successalert = false;
            this.ParkingLotData = {
              parkingLotName: "",
              parkingLotLocation: "",
              userID: []
            };
            this.ParkingLotMapping = {
              parkingLotID: "",
              userID: []
            };
            this._refresh();
            setTimeout(() => {
              this.respondControll(true);
            }, 2000);
          }
        }
      },
      err => {
        console.log(err);
        if (err.error) {
          this.hasError = true;
          this.errorMessage = err.error.error;
        }
        this.loader = true;
      }
    );
  }
  lotMapping() {
    console.log(this.ParkingLotMapping);
    this.parkinglotService.lotMapping(this.ParkingLotMapping).subscribe(
      data => {
        this.ParkingLotData = {
          parkingLotName: "",
          parkingLotLocation: "",
          userID: []
        };
        this.ParkingLotMapping = {
          parkingLotID: "",
          userID: []
        };
        this.successalert = false;
        this._refresh();
        this.getCleaners();
        setTimeout(() => {
          this.respondControll(true);
        }, 1000);
      },
      err => {
        console.log(err);
        this.loader = true;
      }
    );
  }
  async Lotupdate() {
    this.submitted = true;
    this.errors = {};
    let valid = await this.checkValid();

    let fields = [
      {
        field: "parkingLotName",
        label: "Parkinglot name",
        regex: constants.default.REGEX.ALPHA_NUMERIC_NAME
      },
      {
        field: "parkingLotLocation",
        label: "Parkinglot location",
        regex: constants.default.REGEX.ALPHA_NUMERIC_LOCATION
      }
    ];
    for (const item of fields) {
      let _valid = this.checkLimits(
        valid,
        item.field,
        item.field === "parkingLotName"
          ? constants.default.CHARACTER_LIMIT_FOR_NAME
          : constants.default.ALPHA_NUMERIC_LOCATION,
        item.label,
        true,
        item.regex
      );
      if (!_valid) return;
    }

    if (valid) {
      this.loader = false;
      this.parkinglotService.lotEdit(valid).subscribe(
        data => {
          if (data.status == 200) {
            this.successalert = false;
            this.submitted = false;
            this.errors = {};

            this._refresh();
            setTimeout(() => {
              this.ParkingLotData = {
                parkingLotName: "",
                parkingLotLocation: "",
                userID: null
              };
              this.respondControll(false);
            }, 2000);
          }
        },
        err => {
          this._refresh();
          console.log(err);
          if (err.error) {
            this.hasError = true;
            this.errorMessage = err.error.error;
          }
        }
      );
    }
  }
  async checkValid() {
    Object.keys(this.specifiedLot).forEach(key => {
      if (!this.specifiedLot[key]) {
        this.errors[key] = `Field is required`;
      }
    });
    if (Object.keys(this.errors).length || !this.MappedCleaners.length) {
      this._refresh();
      return false;
    }

    let updatedata = {
      parkingLotName: this.specifiedLot.parkingLotName,
      parkingLotLocation: this.specifiedLot.parkingLotLocation,
      parkingLotID: this.specifiedLot.parkingLotID,
      previousUserID: [],
      mappingID: [],
      userID: []
    };
    updatedata.userID = this.MappedCleaners;
    for (let i = 0; i < this.specifiedLot.parkinglotdetails.length; i++) {
      updatedata.mappingID.push(
        this.specifiedLot.parkinglotdetails[i].mappingID
      );
      updatedata.previousUserID.push(
        this.specifiedLot.parkinglotdetails[i].userID
      );
    }
    return updatedata;
  }
  respondControll(data) {
    data ? $("#addnew").modal("hide") : $("#edit").modal("hide");
    this.successalert = true;
  }
  Search() {
    this.searchText = this.presearchText;
  }
  ShowAll() {
    let showall = document.getElementById("showAll");
    showall.classList.add("active");

    let el = document.getElementsByClassName("filter-by-date");
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove("active");
    }
    this.ChooseDate = null;
    this.SortByValue = "PN";
    this.SortType = "A";
    this._refresh();
  }
  FilterbyDate() {
    if (this.ChooseDate) {
      let showall = document.getElementById("showAll");
      showall.classList.remove("active");
      let el = document.getElementsByClassName("filter-by-date");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.add("active");
      }
      let choosedate =
        this.ChooseDate.year +
        "-" +
        this.ChooseDate.month +
        "-" +
        this.ChooseDate.day;
      this._refresh(choosedate);
    }
  }
  setPage(action, page: number) {
    if (action == "button") {
      window.scrollTo(0, 0);
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.parkinglotArray = this.pagedItems;
  }
  sortBy() {
    let showall = document.getElementById("showAll");
    showall.classList.remove("active");
    let sort = document.getElementsByClassName("sort-by");
    for (let i = 0; i < sort.length; i++) {
      sort[i].classList.add("active");
    }
    if (!this.ChooseDate) {
      let el = document.getElementsByClassName("filter-by-date");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove("active");
      }
    }

    this.parkinglotArray = this.allItems;
    if (this.SortByValue == "PN") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort(function(x, y) {
          if (x.parkingLotName.toUpperCase() < y.parkingLotName.toUpperCase())
            return -1;
          if (x.parkingLotName.toUpperCase() > y.parkingLotName.toUpperCase())
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort((x, y) => {
          if (x.parkingLotName.toUpperCase() > y.parkingLotName.toUpperCase())
            return -1;
          if (x.parkingLotName.toUpperCase() < y.parkingLotName.toUpperCase())
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    } else if (this.SortByValue == "PA") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort(function(x, y) {
          if (
            x.parkingLotLocation.toUpperCase() <
            y.parkingLotLocation.toUpperCase()
          )
            return -1;
          if (
            x.parkingLotLocation.toUpperCase() >
            y.parkingLotLocation.toUpperCase()
          )
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort((x, y) => {
          if (
            x.parkingLotLocation.toUpperCase() >
            y.parkingLotLocation.toUpperCase()
          )
            return -1;
          if (
            x.parkingLotLocation.toUpperCase() <
            y.parkingLotLocation.toUpperCase()
          )
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    } else if (this.SortByValue == "CN") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort(function(x, y) {
          if (
            x.parkinglotdetails.userdetails.name.toUpperCase() <
            y.parkinglotdetails.userdetails.name.toUpperCase()
          )
            return -1;
          if (
            x.parkinglotdetails.userdetails.name.toUpperCase() >
            y.parkinglotdetails.userdetails.name.toUpperCase()
          )
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort((x, y) => {
          if (
            x.parkinglotdetails.userdetails.name.toUpperCase() >
            y.parkinglotdetails.userdetails.name.toUpperCase()
          )
            return -1;
          if (
            x.parkinglotdetails.userdetails.name.toUpperCase() <
            y.parkinglotdetails.userdetails.name.toUpperCase()
          )
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    } else if (this.SortByValue == "DT") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort(function(x, y) {
          if (x.updatedAt < y.updatedAt) return -1;
          if (x.updatedAt > y.updatedAt) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.parkinglotArray;
        sortArray.sort((x, y) => {
          if (x.updatedAt > y.updatedAt) return -1;
          if (x.updatedAt < y.updatedAt) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    }
    this.loader = true;
  }

  DeleteLot() {
    this.loader = false;
    this.parkinglotService.deletelot(this.specifiedLot).subscribe(
      data => {
        this._refresh();
      },
      err => {
        this.loader = true;
        console.log(err);
      }
    );
  }
  closehide = true;

  SearchSelect() {
    this.closehide = false;
    this.allItems = this.keepData;

    this.allItems = filterBySearch(
      "parkingLotName",
      this.allItems,
      this.searchText
    );

    this.sortBy();

    if (!this.searchText) {
      this.allItems = this.keepData;
      this.closehide = true;
      this.sortBy();
    }
  }

  clearsearch() {
    this.searchText = "";
    this.presearchText = "";
    this.closehide = true;
    this.allItems = this.keepData;
    this.setPage("none", this.pager.currentPage);
  }
  resetForm() {
    var el = document.getElementsByName("CheckBoxInputName");
    for (let i = 0; i < el.length; i++) {
      let els = el[i] as HTMLInputElement;
      els.checked = false;
    }
    this.clearErrors();
  }
  clearErrors() {
    this.successalert = true;
    this.ParkingLotData = {
      parkingLotName: "",
      parkingLotLocation: "",
      userID: null
    };
    this.errors = {};
    this.submitted = false;
    this.errorMessage = "";
    this.hasError = false;
  }
  modalclose() {
    this.ParkingLotData = {
      parkingLotName: "",
      parkingLotLocation: "",
      userID: null
    };
    this.errors = {};
    this.submitted = false;
    this.errorMessage = "";
    this.hasError = false;
    this._refresh();
  }

  convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item
      };
    }, initialValue);
  };
  CleanerChoose(event) {
    if (event.target.checked) {
      this.MappedCleaners.push(event.target.value);
    } else {
      let index = this.MappedCleaners.indexOf(event.target.value);
      if (index > -1) {
        this.MappedCleaners.splice(index, 1);
      }
    }
    this.ParkingLotData.userID = [];
    console.log(this.MappedCleaners);

    this.ParkingLotData.userID = this.MappedCleaners;
  }
  getAllMapping() {
    this.parkinglotService.getAllMapping().subscribe(
      data => {
        let mapparray = data.data;
        this.userList = this.userlistcheck.filter(
          item => !mapparray.some(m => m.userID === item.userID)
        );

        // for (let i = 0; i < mapparray.length; i++) {
        //   for (let j = 0; j < this.userList.length; j++) {
        //     if (mapparray[i].userID == this.userList[j].userID) {
        //       this.userList.splice(j, 1);
        //     }
        //   }
        // }
      },
      err => {
        console.log(err);
      }
    );
  }
  alphaNumeric(event: any, field: string, isEdit = false) {
    let formData = isEdit ? this.specifiedLot : this.ParkingLotData;
    if (
      field === "parkingLotName" &&
      event.target.value.length >= constants.default.CHARACTER_LIMIT_FOR_NAME
    )
      return false;
    if (
      field === "parkingLotLocation" &&
      event.target.value.length >=
        constants.default.CHARACTER_LIMIT_FOR_LOCATION
    )
      return false;
    let alphaNumericRegex = /^[a-zA-Z0-9-]/;
    if (formData[field] && event.code === "Space") return true;

    return alphaNumericRegex.test(event.key);
  }
  logout() {
    $("#logout").modal();
  }
  checkLimits(obj, field, limit, label, isString = false, regex) {
    if (isString && !regex.test(obj[field])) {
      this.hasError = true;
      this.errorMessage = `Invalid ${label}`;
      return false;
    }

    if (
      (isString && obj[field].length > limit) ||
      (!isString && obj[field] > limit)
    ) {
      this.hasError = true;
      this.errorMessage = `${label} ${isString
        ? "length"
        : ""} should be below ${isString ? "" : "AED"} ${limit}`;
      return false;
    }
    this.hasError = false;
    this.errorMessage = "";
    return true;
  }
}
