import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./screens/login/login.component";
import { ParkingLotsComponent } from "./screens/parking-lots/parking-lots.component";
import { AuthGuardGuard } from "./_helpers/auth-guard.guard";
import { CleanersComponent } from "./screens/cleaners/cleaners.component";
import { ServicesComponent } from "./screens/services/services.component";
import { PromocodeComponent } from "./screens/promocode/promocode.component";
import { DailystatisticsComponent } from "./screens/dailystatistics/dailystatistics.component";
import { FinancialComponent } from "./screens/financial/financial.component";
import { BookinghistoryComponent } from "./screens/bookinghistory/bookinghistory.component";
import { GenerateinvoiceComponent } from "./screens/generateinvoice/generateinvoice.component";
import { InvoiceprintComponent } from "./screens/invoiceprint/invoiceprint.component";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "parkinglots",
    component: ParkingLotsComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "cleaners",
    component: CleanersComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "services",
    component: ServicesComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "promotions",
    component: PromocodeComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "statistics",
    component: DailystatisticsComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "financial",
    component: FinancialComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "booking",
    component: BookinghistoryComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "invoioce",
    component: GenerateinvoiceComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: "invoiceprint",
    component: InvoiceprintComponent,
    canActivate: [AuthGuardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
