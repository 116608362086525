import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { IpService } from "../_helpers/ip.service";
import { AuthenticationService } from "./authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
@Injectable({
  providedIn: "root"
})
export class BookingsService {
  headers: HttpHeaders;

  constructor(
    private authenticationService: AuthenticationService,
    private ipservice: IpService,
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append(
      "x-access-token",
      authenticationService.currentUserValue.access_token
    );
  }
  getAll() {
    let url = this.ipservice.ip + "/bookings/getAll";
    return this.http
      .get<any>(url, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  bookingInvoice(data) {
    let url = this.ipservice.ip + "/bookings/invoice";
    return this.http
      .post<any>(url, data, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  parkingDetials() {
    let url = this.ipservice.ip + "/bookings/parkingDetails";

    return this.http
      .get<any>(url, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  getparkingDetials(body) {
    let url = this.ipservice.ip + "/bookings/getparkingDetails";

    return this.http
      .post<any>(url, body, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
