import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { IpService } from "../_helpers/ip.service";
import { AuthenticationService } from "./authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import "rxjs/add/operator/map";
@Injectable({
  providedIn: "root"
})
export class UserService {
  headers: HttpHeaders;
  constructor(
    private authenticationService: AuthenticationService,
    private ipservice: IpService,
    private http: HttpClient
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append(
      "x-access-token",
      authenticationService.currentUserValue.access_token
    );
  }
  getAllcleaners() {
    let url = this.ipservice.ip + "/auth/fetchcleaners";
    return this.http
      .get<any>(url, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  AddCleaner(body) {
    let url = this.ipservice.ip + "/auth/signup";
    return this.http
      .post<any>(url, body, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  EditCleaner(body) {
    console.log(body);
    let url = this.ipservice.ip + "/auth/edit";
    return this.http
      .post<any>(url, body, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  delete(body) {
    console.log(body);
    let url = this.ipservice.ip + "/auth/delete";
    return this.http
      .post<any>(url, body, { headers: this.headers })
      .pipe(
        map(res => {
          return res;
        })
      );
  }
}
