import { Injectable } from "@angular/core";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: "root"
})
export class InvoiceService {
  fromDate: NgbDate;
  toDate: NgbDate;
  constructor() {}
}
