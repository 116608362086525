import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { IpService } from "../_helpers/ip.service";
import { AuthenticationService } from "./authentication.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import "rxjs/add/operator/map";
import * as moment from "moment";
@Injectable({
  providedIn: "root",
})
export class ParkinglotService {
  headers: HttpHeaders;
  constructor(
    private http: HttpClient,
    private ipservice: IpService,
    private authenticationService: AuthenticationService
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append(
      "x-access-token",
      authenticationService.currentUserValue.access_token
    );
  }
  getAlllots() {
    let url = this.ipservice.ip + "/parkinglot/fetch";
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  CreateNew(body) {
    let url = this.ipservice.ip + "/parkinglot/create";
    return this.http.post<any>(url, body, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  lotMapping(body) {
    let url = this.ipservice.ip + "/parkinglot/mapping";
    return this.http.post<any>(url, body, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  lotEdit(body) {
    let url = this.ipservice.ip + "/parkinglot/edit";
    return this.http.put<any>(url, body, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  deletelot(body) {
    let url = this.ipservice.ip + "/parkinglot/delete";
    return this.http.post<any>(url, body, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getAllMapping() {
    let url = this.ipservice.ip + "/parkinglot/getAllmapping";
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  lotDetails(id) {
    let url = this.ipservice.ip + `/parkinglot/${id}`;
    return this.http.get<any>(url, { headers: this.headers }).pipe(
      map((res) => {
        return res;
      })
    );
  }
}
