import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../service/authentication.service";
import { ToastService } from "../_models/globalToast/toast-service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    public toastservice: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        console.log(err);
        if (err.status == 0) {
          alert("Please check your internet connection or try again later");
        }
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          // location.reload(true);
        }
        const error = err;
        return throwError(error);
      })
    );
  }
}
