import { Component, OnInit } from "@angular/core";
import { BookingsService } from "src/app/service/bookings.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { DatePipe } from "@angular/common";
import { PagerService } from "src/app/_helpers/pager.service";
import { ParkinglotService } from "src/app/service/parkinglot.service";
import { UserService } from "src/app/service/user.service";
import { filterByField, filterBySearch } from "src/utils/filters";
import { filterByDate } from "src/utils/filterBydate";
export interface FilterOptions {
  value: string;
  viewValue: string;
}
declare var $: any;
@Component({
  selector: "app-dailystatistics",
  templateUrl: "./dailystatistics.component.html",
  styleUrls: ["./dailystatistics.component.scss"]
})
export class DailystatisticsComponent implements OnInit {
  options: FilterOptions[] = [
    { value: "PL", viewValue: "PARKING LOT" },
    { value: "CN", viewValue: "CLEANER NAME" }
    // { value: "DT", viewValue: "DATE" }
  ];
  loader = false;
  // hidefilterbydate = true;
  hidefilterbyLot = true;
  hidefilterbycleaner = true;
  Filterlot = "";
  FilterCleaner = "";
  Today = new Date();
  searchText = "";
  closehide = true;
  private allItems: any[];
  private allItemskeep: any[];
  TotalCash: any = 0;
  PaidAmount: any = 0;
  NumberofBookings;
  pager: any = {};
  filterBy = "";
  pagedItems: any[];
  specificBooking = {
    id: "",
    bookingID: "",
    availedServices: [],
    promoCode: "",
    Amount: "",
    offerPrize: "",
    paymentStatus: Boolean,
    createdAt: "",
    updatedAt: "",
    customerID: "",
    parkingMapID: "",
    parkingLotID: "",
    parkingLotName: "",
    parkingLotLocation: "",
    userID: "",
    phoneNumber: "",
    usertype: "",
    name: "",
    countrycode: "",
    customerDetails: {
      customerID: "",
      customerName: "",
      customerMobileNumber: "",
      countrycode: "",
      carType: "",
      plateNumber: "",
      carModel: "",
      createdAt: "",
      updatedAt: ""
    },
    netAmount: 0
  };
  constructor(
    private bookingservice: BookingsService,
    private datepipe: DatePipe,
    private pagerService: PagerService,
    private parkinglotservice: ParkinglotService,
    private userservice: UserService
  ) {}
  BookingData;
  FilterDate: NgbDateStruct;
  ngOnInit() {
    this._refresh();
    var d = new Date();
    this.FilterDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
  }
  _refresh(
    field: string = "createdAt",
    value: any = this.datepipe.transform(this.Today, "yyyy-MM-dd"),
    isDateFilter: boolean = true
  ) {
    this.loader = false;
    this.bookingservice.getAll().subscribe(
      data => {
        this.getAllCleaners();
        this.getAlllots();
        this.loader = true;
        data.data = data.data.map(item => ({
          ...item,
          customerDetails: {
            ...item.customerDetails,
            mobileNumber: `+${item.customerDetails.countrycode}-${item
              .customerDetails.customerMobileNumber}`,
            customerName: item.customerDetails.customerName
              ? item.customerDetails.customerName
              : "--"
          }
        }));
        this.setFilters(field, data.data, value, isDateFilter);
      },
      err => {
        console.log(err);
        this.loader = true;
      }
    );
  }
  setFilters(
    field: string = "createdAt",
    data: any = [],
    value: any = "",
    isDateFilter: boolean = false
  ) {
    data = isDateFilter
      ? filterByDate(value, data, field)
      : filterByField(field, data, value);
    this.allItemskeep = data;
    this.allItems = [...data];
    this.findTotal();
    this.pager.currentPage = 1;
    this.setPage("none", this.pager.currentPage);
  }
  FilterbyDate() {
    if (this.FilterDate) {
      let date =
        this.FilterDate.year +
        "-" +
        this.FilterDate.month +
        "-" +
        this.FilterDate.day;
      date = this.datepipe.transform(date, "yyyy-MM-dd");
      this._refresh("createdAt", date, true);
    }
  }
  setPage(action, page: number) {
    this.loader = true;
    if (action == "button") {
      window.scrollTo(0, 0);
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.BookingData = this.pagedItems;
  }
  ClearAll() {
    this.closehide = true;
    this.searchText = "";
    this.allItems = this.allItemskeep;
    this.setPage("none", this.pager.currentPage);
  }
  pickBooking(booking) {
    this.specificBooking = booking;
  }
  SearchSelect() {
    this.closehide = false;
    this.allItems = filterBySearch(
      "customerDetails.customerName",
      this.allItemskeep,
      this.searchText,
      true
    );

    this.setPage("none", this.pager.currentPage);
    if (!this.searchText) {
      this.allItems = this.allItemskeep;
      this.closehide = true;
      this.setPage("none", this.pager.currentPage);
    }
  }

  FilterOptionChange(option) {
    this.getAllCleaners();
    this.getAlllots();
    switch (option) {
      case "PL":
        this.hidefilterbyLot = false;
        this.hidefilterbycleaner = true;
        this.FilterCleaner = "";
        break;
      case "CN":
        this.hidefilterbycleaner = false;
        this.hidefilterbyLot = true;
        this.Filterlot = "";
        break;
      default:
        this.hidefilterbyLot = true;
        this.hidefilterbycleaner = true;
        this.FilterCleaner = "";
        this.Filterlot = "";
    }
  }
  Alllots: FilterOptions[];
  getAlllots() {
    this.parkinglotservice.getAlllots().subscribe(
      data => {
        this.loader = true;
        let lots = data.data;
        this.Alllots = [];
        for (let i = 0; i < lots.length; i++) {
          this.Alllots.push({
            value: lots[i].parkingLotID,
            viewValue: lots[i].parkingLotName
          });
        }
      },
      err => {
        this.loader = true;
      }
    );
  }
  AllCleaners: FilterOptions[];
  getAllCleaners() {
    this.userservice.getAllcleaners().subscribe(
      data => {
        this.loader = true;
        let cleaner = data.data;
        this.AllCleaners = [];
        for (let i = 0; i < cleaner.length; i++) {
          this.AllCleaners.push({
            value: cleaner[i].userID,
            viewValue: cleaner[i].name
          });
        }
      },
      err => {
        this.loader = true;
      }
    );
  }
  FilterByLot() {
    this.loader = false;
    this._refresh("parkingLotID", this.Filterlot, false);
  }
  FilterByCleaner() {
    this.loader = false;
    this.loader = false;
    this._refresh("userID", this.FilterCleaner, false);
  }
  findTotal() {
    let data1 = this.allItems;
    this.TotalCash = 0;
    this.PaidAmount = 0;

    this.TotalCash = data1
      .reduce((partial_sum, a) => partial_sum + a.netAmount, 0)
      .toFixed(2);

    this.PaidAmount = data1
      .filter(item => item.paymentStatus)
      .reduce((partial_sum, a) => partial_sum + a.netAmount, 0)
      .toFixed(2);

    this.NumberofBookings = this.allItems.length;
  }
  ShowAll() {
    this.hidefilterbyLot = true;
    this.hidefilterbycleaner = true;
    this.FilterCleaner = "";
    this.Filterlot = "";
    this.filterBy = null;
    var d = new Date();
    this.FilterDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this._refresh();
  }
  ngOnDestroy() {
    this.ShowAll();
  }
  logout() {
    $("#logout").modal();
  }
}
