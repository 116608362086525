export const IsMobileNumber = txtMobile => {
  var mob = /^[1-9]{1}[0-9]{7,11}$/;
  return mob.test(txtMobile);
};
export const numbersOnly = text => {
  const pattern = /^[0-9]{8,12}$/gm;
  console.log(pattern.test(text), text);

  return pattern.test(text);
};
