import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { User } from "../_models";
import { IpService } from "../_helpers/ip.service";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  constructor(
    private http: HttpClient,
    private ipservice: IpService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(body) {
    return this.http.post<any>(this.ipservice.ip + "/auth/login", body).pipe(
      map((user) => {
        let admin = {
          id: "",
          userName: "",
          phoneNumber: "",
          access_token: "",
        };
        console.log(user);
        if (user.data && user.data.access_token) {
          admin = {
            id: user.data.admin.id,
            userName: user.data.admin.userName,
            phoneNumber: user.data.admin.phoneNumber,
            access_token: user.data.access_token,
          };
          localStorage.removeItem("currentUser");
          localStorage.setItem("currentUser", JSON.stringify(admin));
          this.currentUserSubject.next(admin);
        }

        return user;
      })
    );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    this.currentUserSubject.next(null);
    this.router.navigate(["/login"]);
  }
}
