import * as moment from "moment";

export const filterByDate = (
  date: string,
  data: any,
  field: string = "updatedAt"
) => {
  return data.filter((item: any) =>
    moment(item[field]).isSame(moment(date), "date")
  );
};
export const filterByDateRange = (
  from: string,
  to: string,
  data: any,
  field: string = "updatedAt"
) => {
  return data.filter((item: any) =>
    moment(item[field])
      .set({ hour: 0, minute: 0, second: 0 })
      .isBetween(
        moment(from).set({ hour: 0, minute: 0, second: 0 }),
        moment(to).set({ hour: 0, minute: 0, second: 0 })
      )
  );
};
