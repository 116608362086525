const leaf = (obj, path) =>
  path.split(".").reduce((value, el) => value[el], obj);

export const filterByField = (field: string, data: any, value: any) => {
  return data.filter((item) => item[field] === value);
};
export const filterBySearch = (
  field: string,
  data: any,
  value: any,
  nested: boolean = false
) => {
  if (nested) {
  }
  return data.filter((item) =>
    nested
      ? leaf(item, field).toLowerCase().includes(value.toLowerCase())
      : item[field].toLowerCase().includes(value.toLowerCase())
  );
};
