import { Injectable } from "@angular/core";
declare var $: any;
@Injectable({
  providedIn: "root"
})
export class SidebarService {
  constructor() {}
  collapsesidebar() {
    var el = document.getElementsByClassName("collapse-sidenav-toggle");
    for (let i = 0; i < el.length; i++) {
      if (el[i].classList.contains("navbar-col")) {
        el[i].classList.remove("navbar-col");
        el[i].classList.add("navbar-collapse-col");
      } else {
        el[i].classList.remove("navbar-collapse-col");
        el[i].classList.add("navbar-col");
      }
    }
  }
}
