import { Component, OnInit, HostListener } from "@angular/core";
import { UserService } from "src/app/service/user.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { PagerService } from "src/app/_helpers/pager.service";
import { DatePipe } from "@angular/common";
import { filterByDate } from "src/utils/filterBydate";
import * as constants from "src/constants";
import { IsMobileNumber } from "src/utils/mobileNumberValidation";
import { filterBySearch } from "src/utils/filters";

declare var $: any;
@Component({
  selector: "app-cleaners",
  templateUrl: "./cleaners.component.html",
  styleUrls: ["./cleaners.component.scss"]
})
export class CleanersComponent implements OnInit {
  loader = false;
  ChooseDate: NgbDateStruct;
  dataCount = 0;
  SortByValue = null;
  SortType = null;
  erroralert = true;
  successalert = true;
  errorMsg = "";
  CleanersList;
  searchText;
  errorMessage = "";
  hasError = false;
  CleanerData = {
    phoneNumber: "",
    usertype: "Cleaner",
    name: "",
    countrycode: "+971"
  };
  specificCleaner = {
    userID: "",
    phoneNumber: "",
    countrycode: "",
    usertype: "",
    name: "",
    createdAt: "",
    updatedAt: ""
  };
  private allItems: any[];
  pager: any = {};
  pagedItems: any[];
  keepData: any[];
  constructor(
    private userService: UserService,
    private pagerService: PagerService,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this._refresh();
    this.SortByValue = "CN";
    this.SortType = "A";
  }
  ngAfterViewInit() {
    var el = document.getElementById("showAll");
    el.classList.add("active");
    let sort = document.getElementsByClassName("sort-by");
    for (let i = 0; i < sort.length; i++) {
      sort[i].classList.add("active");
    }
  }
  _refresh(date: string = "") {
    this.loader = false;
    this.userService.getAllcleaners().subscribe(
      data => {
        this.loader = true;
        let array = date ? filterByDate(date, data.data) : data.data;
        array = array.map(item => ({
          ...item,
          mobileNumber: `+${item.countrycode}-${item.phoneNumber}`
        }));
        this.dataCount = array.length;
        this.CleanersList = [...array];
        this.keepData = [...array];
        this.allItems = [...array];
        this.sortBy();
        this.setPage("none", 1);
        if (!date) this.ChooseDate = null;
      },
      err => {
        console.log(err);
        this.loader = true;
      }
    );
  }
  @HostListener("window:keydown", ["$event"])
  onKeyPress($event: KeyboardEvent) {
    var k = $event.keyCode;
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode == 86)
      return k > 47 && k < 58;
  }
  omit_alphabets_char(event, limit) {
    const charCode = event.which ? event.which : event.keyCode;
    if (
      (charCode > 31 && (charCode < 48 || charCode > 57)) ||
      event.target.value.length >= limit
    ) {
      return false;
    }
    return true;
  }
  AddCleaner(body) {
    if (!body.phoneNumber || !body.name || !body.countrycode) {
      this.errorMsg = "All fields are mandatory";
      this.erroralert = false;
      return;
    } else {
      this.errorMsg = "";
      this.erroralert = true;
    }
    if (!IsMobileNumber(body.phoneNumber)) {
      this.errorMsg = "Invalid mobile number";
      this.erroralert = false;
      return;
    }
    if (body.phoneNumber.length < 8 || body.phoneNumber.length > 12) {
      this.errorMsg = "Enter valid mobile number";
      this.erroralert = false;
      return;
    }
    body = { ...body, countrycode: body.countrycode.replace("+", "") };
    let fields = [
      {
        field: "name",
        label: "Cleaner name",
        regex: constants.default.REGEX.ALPHABETS_ONLY
      }
    ];
    for (const item of fields) {
      let _valid = this.checkLimits(
        body,
        item.field,
        constants.default.CHARACTER_LIMIT_FOR_NAME,
        item.label,
        true,
        item.regex
      );
      if (!_valid) return;
    }
    body.phoneNumber = body.phoneNumber;
    if (this.CleanersList.length) {
      for (var i = 0; i < this.CleanersList.length; i++) {
        let number1 =
          this.CleanersList[i].countrycode + this.CleanersList[i].phoneNumber;
        let number2 = body.countrycode + body.phoneNumber;
        if (number1 == number2) {
          this.errorMsg = "Phone number already exist";
          this.erroralert = false;
          return;
        }
      }
    }
    this.loader = false;
    this.userService.AddCleaner(body).subscribe(
      data => {
        this.loader = true;
        this.errorMsg = "";
        this.erroralert = true;
        this.successalert = false;
        setTimeout(() => {
          this.successalert = true;
          $("#addnew").modal("hide");
        }, 1000);
        this._refresh();
      },
      err => {
        console.log(err);
        if (err.error) {
          this.hasError = true;
          this.errorMessage = err.error.error;
        }
        this.loader = true;
      }
    );
  }
  ClearAll() {
    this.searchText = "";
  }
  previosnumber = "";
  pickCleaner(cleaner) {
    this.errorMsg = "";
    this.erroralert = true;
    this.successalert = true;
    this.specificCleaner = {
      ...cleaner,
      countrycode: `+${cleaner.countrycode}`
    };
    this.previosnumber = cleaner.countrycode + cleaner.phoneNumber;
  }
  EditCleaner(body) {
    var valid = true;
    if (!body.phoneNumber || !body.name || !body.countrycode) {
      this.errorMsg = "All fields are mandatory";
      this.erroralert = false;
      this._refresh();
      return;
    } else {
      this.errorMsg = "";
      this.erroralert = true;
    }
    if (!IsMobileNumber(body.phoneNumber)) {
      this.errorMsg = "Invalid mobile number";
      this.erroralert = false;
      return;
    }
    body = { ...body, countrycode: body.countrycode.replace("+", "") };
    let existingPhonenumber;
    let fields = [
      {
        field: "name",
        label: "Cleaner name",
        regex: constants.default.REGEX.ALPHABETS_ONLY
      }
    ];
    for (const item of fields) {
      let _valid = this.checkLimits(
        body,
        item.field,
        constants.default.CHARACTER_LIMIT_FOR_NAME,
        item.label,
        true,
        item.regex
      );
      if (!_valid) return;
    }
    let newPhonenumber = body.countrycode + body.phoneNumber;
    this.userService.getAllcleaners().subscribe(
      data => {
        if (data.data.length) {
          let CleanersList = data.data;
          for (var i = 0; i < CleanersList.length; i++) {
            existingPhonenumber =
              CleanersList[i].countrycode + CleanersList[i].phoneNumber;

            if (
              existingPhonenumber == newPhonenumber &&
              this.previosnumber != newPhonenumber
            ) {
              this.errorMsg = "Phone number already exist";
              this.erroralert = false;
              valid = false;
              return;
            }
          }
          this.loader = false;
          this.userService.EditCleaner(body).subscribe(
            data => {
              this.errorMsg = "";
              this.erroralert = true;
              this.successalert = false;
              setTimeout(() => {
                this.successalert = true;
                $("#edit").modal("hide");
              }, 1000);
              this._refresh();
              this.ClearAll();
            },
            err => {
              if (err.error) {
                this.hasError = true;
                this.errorMessage = err.error.error;
              }
              this.loader = true;
              console.log(err);
            }
          );
        }
      },
      err => {
        this.loader = true;
        console.log(err);
        return;
      }
    );
  }
  Delete() {
    this.loader = false;
    this.userService.delete(this.specificCleaner).subscribe(
      data => {
        console.log(data);
        this._refresh();
      },
      err => {
        console.log(err);
        this.loader = true;
      }
    );
  }
  ShowAll() {
    let showall = document.getElementById("showAll");
    showall.classList.add("active");

    let el = document.getElementsByClassName("filter-by-date");
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove("active");
    }
    this.ChooseDate = null;
    this.SortByValue = "CN";
    this.SortType = "A";
    this.sortBy();
    this._refresh();
  }
  FilterbyDate() {
    if (this.ChooseDate) {
      let showall = document.getElementById("showAll");
      showall.classList.remove("active");
      let el = document.getElementsByClassName("filter-by-date");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.add("active");
      }
      let choosedate =
        this.ChooseDate.year +
        "-" +
        this.ChooseDate.month +
        "-" +
        this.ChooseDate.day;
      this._refresh(choosedate);
    }
  }
  setPage(action, page: number) {
    if (action == "button") {
      window.scrollTo(0, 0);
    }
    this.pager = this.pagerService.getPager(this.allItems.length, page);
    this.pagedItems = this.allItems.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );
    this.CleanersList = this.pagedItems;
    console.log(this.CleanersList);
  }
  sortBy() {
    let showall = document.getElementById("showAll");
    showall.classList.remove("active");
    let sort = document.getElementsByClassName("sort-by");
    for (let i = 0; i < sort.length; i++) {
      sort[i].classList.add("active");
    }
    if (!this.ChooseDate) {
      let el = document.getElementsByClassName("filter-by-date");
      for (let i = 0; i < el.length; i++) {
        el[i].classList.remove("active");
      }
    }
    this.CleanersList = this.allItems;
    if (this.SortByValue == "CN") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.CleanersList;
        sortArray.sort(function(x, y) {
          if (x.name.toUpperCase() < y.name.toUpperCase()) return -1;
          if (x.name.toUpperCase() > y.name.toUpperCase()) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.CleanersList;
        sortArray.sort((x, y) => {
          if (x.name.toUpperCase() > y.name.toUpperCase()) return -1;
          if (x.name.toUpperCase() < y.name.toUpperCase()) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    } else if (this.SortByValue == "PN") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.CleanersList;
        sortArray.sort(function(x, y) {
          if (x.phoneNumber.toUpperCase() < y.phoneNumber.toUpperCase())
            return -1;
          if (x.phoneNumber.toUpperCase() > y.phoneNumber.toUpperCase())
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.CleanersList;
        sortArray.sort((x, y) => {
          if (x.phoneNumber.toUpperCase() > y.phoneNumber.toUpperCase())
            return -1;
          if (x.phoneNumber.toUpperCase() < y.phoneNumber.toUpperCase())
            return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    } else if (this.SortByValue == "DT") {
      if (this.SortType == "A") {
        let sortArray = [];

        sortArray = this.CleanersList;
        sortArray.sort(function(x, y) {
          if (x.updatedAt < y.updatedAt) return -1;
          if (x.updatedAt > y.updatedAt) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      } else if (this.SortType == "D") {
        let sortArray = [];

        sortArray = this.CleanersList;
        sortArray.sort((x, y) => {
          if (x.updatedAt > y.updatedAt) return -1;
          if (x.updatedAt < y.updatedAt) return 1;
          return 0;
        });

        this.allItems = sortArray;
        this.setPage("none", this.pager.currentPage);
      }
    }
  }

  closehide = true;

  SearchSelect() {
    this.closehide = false;
    this.allItems = filterBySearch("name", this.keepData, this.searchText);
    this.sortBy();
    if (!this.searchText) {
      this.closehide = true;
      this.allItems = this.keepData;
      this.sortBy();
    }
  }
  clearsearch() {
    this.searchText = "";
    this.closehide = true;
    this.allItems = this.keepData;
    this.setPage("none", this.pager.currentPage);
  }
  resetForm() {
    this.CleanerData = {
      phoneNumber: "",
      usertype: "Cleaner",
      name: "",
      countrycode: "+971"
    };
    this.erroralert = true;
    this.successalert = true;
    this.clearErrors();
  }
  clearErrors() {
    this.successalert = true;
    this.CleanerData = {
      phoneNumber: "",
      usertype: "Cleaner",
      name: "",
      countrycode: "+971"
    };

    this.errorMessage = "";
    this.hasError = false;
  }
  modalclose() {
    this._refresh();
    this.errorMsg = "";
    this.erroralert = true;
    this.errorMessage = "";
    this.hasError = false;
  }
  omit_special_char(event: any) {
    // let alphaNumericRegex = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 ]*)?$/;
    let alphaNumericRegex = /^[a-zA-Z0-9]/;
    if (
      (!(event.target.value && event.code === "Space") &&
        !alphaNumericRegex.test(event.key)) ||
      event.target.value.length >= constants.default.CHARACTER_LIMIT_FOR_NAME
    ) {
      return false;
    }
    return true;
  }
  logout() {
    $("#logout").modal();
  }
  checkLimits(obj, field, limit, label, isString = false, regex) {
    if (isString && !regex.test(obj[field])) {
      this.hasError = true;
      this.errorMessage = `Invalid ${label}`;
      return false;
    }
    if (
      (isString && obj[field].length > limit) ||
      (!isString && obj[field] > limit)
    ) {
      this.hasError = true;
      this.errorMessage = `${label} ${isString
        ? "length"
        : ""} should be below ${isString ? "" : "AED"} ${limit}`;
      return false;
    }
    this.hasError = false;
    this.errorMessage = "";
    return true;
  }
  appendPlusSymbol(data) {
    if (data.countrycode && !data.countrycode.includes("+")) {
      data.countrycode = `+${data.countrycode}`;
    }
  }
}
