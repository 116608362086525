import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { BookingsService } from "src/app/service/bookings.service";
declare var $: any;

@Component({
  selector: "app-invoiceprint",
  templateUrl: "./invoiceprint.component.html",
  styleUrls: ["./invoiceprint.component.scss"]
})
export class InvoiceprintComponent implements OnInit {
  today: Date;
  loader = false;
  invoiceData = {
    Amount: "",
    availedServices: [],
    bookingID: "",
    createdAt: "",
    parkingLotID: "",
    parkingLotName: "",
    parkingLotLocation: "",
    phoneNumber: "",
    usertype: "",
    name: "",
    countrycode: "",
    paymentMethod: "",
    customerDetails: {
      carModel: "",
      carType: "",
      createdAt: "",
      customerID: "",
      customerMobileNumber: "",
      customerName: "",
      countrycode: "",
      plateNumber: "",
      updatedAt: ""
    },
    customerID: "",
    id: "",
    offerPrize: "",
    parkingMapID: "",
    paymentStatus: Boolean,
    promoCode: "",
    updatedAt: "",
    netAmount: 0
  };
  parkingLotData = {
    parkingLotID: "",
    parkingLotName: "",
    parkingLotLocation: "",
    createdAt: "",
    updatedAt: ""
  };
  creditType;
  cashType;
  creditChecked = false;
  cashChecked = true;
  serialNumber;
  invoiceDate = moment().format("MMMM DD,YYYY");
  constructor(private bookinservice: BookingsService) {}
  ngOnInit() {
    this.today = new Date();
    this.serialNumber = moment().format("DDYYYY");
    this.getbooking();
  }
  getbooking() {
    let body = {
      id: localStorage.getItem("bookid")
    };

    this.bookinservice.bookingInvoice(body).subscribe(
      data => {
        // this.getparkinDetails(data.data);

        this.loader = true;
        if (data.data) {
          data.data = {
            ...data.data,
            customerDetails: {
              ...data.data.customerDetails,
              mobileNumber: `+${data.data.customerDetails.countrycode}-${data
                .data.customerDetails.customerMobileNumber}`,
              customerName: data.data.customerDetails.customerName
                ? data.data.customerDetails.customerName
                : "--"
            }
          };
        }
        this.invoiceData = data.data;

        if (data.data.paymentMethod) {
          data.data.paymentMethod = data.data.paymentMethod.toUpperCase();
        }

        console.log(data);
        this.PaymentType(data.data.paymentMethod);
      },
      err => console.log(err)
    );
  }
  getparkinDetails(body) {
    this.bookinservice.getparkingDetials(body).subscribe(
      data => {
        console.log(data);
        this.parkingLotData = data.data[0].parkinglotdetails;
        console.log(this.parkingLotData);
        this.loader = true;
      },
      err => {
        console.log(err);
      }
    );
  }
  print() {
    window.print();
  }
  PaymentType(type) {
    if (type == "CASH") {
      this.cashChecked = true;
      this.creditChecked = false;
    } else {
      this.creditChecked = true;
      this.cashChecked = false;
    }
  }
}
