import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookinghistory',
  templateUrl: './bookinghistory.component.html',
  styleUrls: ['./bookinghistory.component.scss']
})
export class BookinghistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
